import { useId } from 'react';

import { VisibleSeoBlockRecord } from '@hultafors/eripiowear/types';

import { Section } from '../section/section';
import { TextBlock } from '../text-block/text-block';

export type VisibleSeoBlockProps = Partial<VisibleSeoBlockRecord>;

export const VisibleSeoBlock: React.FC<VisibleSeoBlockProps> = ({
  title,
  description,
}) => {
  const uid = useId();
  return (
    <Section largePaddingBottomDesktop darkTopBorder>
      <TextBlock id={uid} header={title || ''} body={description || ''} />
    </Section>
  );
};
