import { LinkButtonStyled } from './link-button.styled';

export interface LinkButtonProps {
  text: string;
  url?: string;
  mailTo?: boolean;
  large?: boolean;
  mailToLink?: string;
  mailSubject?: string;
  className?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  text = '',
  url = '',
  mailTo = false,
  mailToLink = '',
  large,
  className,
}) => (
  <LinkButtonStyled
    href={mailTo ? `mailto:${mailToLink}` : url}
    $large={large}
    className={className}
  >
    {text}
  </LinkButtonStyled>
);
