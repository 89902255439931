import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontSizes, lineHeights } from '@hultafors/eripiowear/helpers';

interface LinkButtonStyledProps {
  $large?: boolean;
}

export const LinkButtonStyled = styled(Link)<LinkButtonStyledProps>`
  display: block;
  height: auto;
  padding-block: ${({ $large }) => ($large ? '1rem' : '0.5rem')};
  padding-inline: ${({ $large }) => ($large ? '2rem' : '2rem')};
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  box-sizing: border-box;
  border: thin solid ${colors.orange};
  background-color: ${colors.orange};
  color: ${colors.white};

  .color &.Grey {
    background-color: ${colors.gray4};
    border-color: ${colors.gray4};
    color: ${colors.solidBlack};

    &:hover {
      background-color: ${colors.grayBtnHover};
      border-color: ${colors.grayBtnHover};
    }
  }

  &:hover {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }

  text-decoration: none;
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  text-transform: uppercase;
`;
