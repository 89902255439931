import { TextBlockFragment } from '@hultafors/eripiowear/types';

import { LinkButton } from '../link-button/link-button';
import { Markdown } from '../markdown/markdown';

import { Heading, Inner, TextBlockStyled } from './text-block.styled';

export const TextBlock: React.FC<TextBlockFragment> = ({
  header,
  body,
  ctaLabel,
  ctaLink,
  ctaPageLink,
}) => {
  const href = ctaLink || ctaPageLink?.slug || '';
  return (
    <TextBlockStyled>
      <Inner>
        {header && <Heading>{header}</Heading>}
        {body && <Markdown options={{ forceBlock: true }}>{body}</Markdown>}
        {!!ctaLabel && !!href && (
          <LinkButton
            large={true}
            text={ctaLabel}
            url={href}
            className="Grey CTAButton"
          />
        )}
      </Inner>
    </TextBlockStyled>
  );
};
