import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/eripiowear/helpers';

const ContentBlock = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ContentBlock),
);

export const TextBlockStyled = styled(ContentBlock)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 15px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`;

export const Heading = styled.h2`
  font-weight: ${fontWeights.normal};
  font-size: 2.5rem;
  line-height: ${lineHeights.headline};

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: 3rem;
  }
`;

export const Inner = styled.div`
  grid-column: span 5;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-column: 2 / span 10;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 3 / span 8;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    grid-column: 4 / span 6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0;
  }

  p,
  li {
    line-height: 24px;
    margin: 12px 0;
    padding: 6px 0;
    white-space: pre-wrap;

    @media screen and (min-width: ${breakpoints.desktop}) {
      line-height: 26px;
    }
  }

  p {
    padding-block: 0;
  }

  p:first-child,
  li:first-child {
    margin-block-start: 0;
  }

  ol,
  ul {
    margin: 24px 0;
  }

  .CTAButton {
    display: inline-block;
    margin-block-start: ${spacing.tiny};
  }
`;
